import React, { useEffect, Fragment, useState } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { BackButton, GridRow, Column } from '../../components/common';
import { DatePicker } from '../../components/Pickers';
import { types as loanActions } from '../../actions/loanActions';
import { statuses } from '../../helpers/constants';
import Loading from '../../components/Loading';
import { scrollToTop } from '../../helpers/util';
import './index.css';
import { types as longRunningTypes } from '../../actions/longRunningProcessActions';

export const IowaExports = ({ history }) => {
  const [state, setState] = useState({});
  const loans = useSelector(state => state.loans);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
    reduxDispatch({
      type: loanActions.GET_LOANS,
    });
  }, []);

  if (loans.getLoansStatus === statuses.PROCESSING) {
    return <Loading />;
  }

  const doDateSet = (dt, label) => {
    const newState = { ...state };
    newState[label] = dt;
    setState(newState);
  };

  const requestContributionCsv = () => {
    const downloadFileName = `iowa_contribution_export_${Moment.utc(state.contributionStartDate).format('MM_DD_YYYY')}__${Moment.utc(state.contributionEndDate).format('MM_DD_YYYY')}.csv`;
    reduxDispatch({
      type: longRunningTypes.LONG_RUNNING_JOB_WITH_CSV,
      data: {
        jobName: 'iowa-contribution-export',
        prettyJobName: 'Iowa Contribution Export',
        downloadFileName,
        payload: {
          startDate: Moment.utc(state.contributionStartDate).format('YYYY-MM-DDT00:00:00.000+00:00'),
          endDate: Moment.utc(state.contributionEndDate).format('YYYY-MM-DDT23:59:59.999+00:00'),
          isFinalJob: true,
        },
      },
    });
  };

  const requestExpenditureCsv = () => {
    const downloadFileName = `iowa_expenditure_export_${Moment.utc(state.expenditureStartDate).format('MM_DD_YYYY')}__${Moment.utc(state.expenditureEndDate).format('MM_DD_YYYY')}.csv`;
    reduxDispatch({
      type: longRunningTypes.LONG_RUNNING_JOB_WITH_CSV,
      data: {
        jobName: 'iowa-expenditure-export',
        prettyJobName: 'Iowa Expenditure Export',
        downloadFileName,
        payload: {
          startDate: Moment.utc(state.expenditureStartDate).format('YYYY-MM-DDT00:00:00.000+00:00'),
          endDate: Moment.utc(state.expenditureEndDate).format('YYYY-MM-DDT23:59:59.999+00:00'),
          isFinalJob: true,
        },
      },
    });
  };

  return (
    <Fragment>
      <BackButton history={history} />
      <div className="IowaExports depth-1">
        <header className="loans-header">
          <h3>Iowa Contribution Export</h3>
        </header>
        <GridRow>
          <Column sm={4}>
            <DatePicker
              label="Start Date"
              value={state.contributionStartDate}
              onChange={(dt) => doDateSet(dt, 'contributionStartDate')}
            />
          </Column>
          <Column sm={4}>
            <DatePicker
              label="End Date"
              value={state.contributionEndDate}
              onChange={(dt) => doDateSet(dt, 'contributionEndDate')}
            />
          </Column>
          <Column sm={4}>
            <PrimaryButton
              text="Export"
              onClick={requestContributionCsv}
              style={{ marginRight: 16, marginTop: 28 }}
            />
          </Column>
        </GridRow>
      </div>
      <div className="IowaExports depth-1">
        <header className="loans-header">
          <h3>Iowa Expenditure Export</h3>
        </header>
        <GridRow>
          <Column sm={4}>
            <DatePicker
              label="Start Date"
              value={state.expenditureStartDate}
              onChange={(dt) => doDateSet(dt, 'expenditureStartDate')}
            />
          </Column>
          <Column sm={4}>
            <DatePicker
              label="End Date"
              value={state.expenditureEndDate}
              onChange={(dt) => doDateSet(dt, 'expenditureEndDate')}
            />
          </Column>
          <Column sm={4}>
            <PrimaryButton
              text="Export"
              onClick={requestExpenditureCsv}
              style={{ marginRight: 16, marginTop: 28 }}
            />
          </Column>
        </GridRow>
      </div>
    </Fragment>
  );
};

IowaExports.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(IowaExports);
