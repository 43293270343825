import { createReducer } from '../../../reducers/createReducer';
import { calcFilters } from '../../../helpers/listsHelper';

export const defaultFilters = [
  {
    column: 'transactionType',
    expression: 'equals',
    value: 'contact',
  },
  {
    column: 'transactionType',
    expression: 'exists',
    value: 'contact',
  },
];

export const contactColumns = [
  { key: 'contactType', text: 'Contact Type' },
  { key: 'displayName', text: 'Display Name' },
  { key: 'businessName', text: 'Business Name' },
  { key: 'businessType', text: 'Business Type' },
  { key: 'committeeAffiliation', text: 'Committee Affiliation' },
  { key: 'taxEin', text: 'Tax Ein' },
  { key: 'salutation', text: 'Salutation' },
  { key: 'firstName', text: 'First Name' },
  { key: 'middleName', text: 'Middle Name' },
  { key: 'lastName', text: 'Last Name' },
  { key: 'suffix', text: 'Suffix' },
  { key: 'nickName', text: 'Nickname' },
  { key: 'occupation', text: 'Occupation' },
  { key: 'employer', text: 'Employer' },
  { key: 'contactType', text: 'Contact Type' },
  { key: 'addressLine1', text: 'Address Line1' },
  { key: 'addressLine2', text: 'Address Line2' },
  { key: 'city', text: 'City' },
  { key: 'state', text: 'State' },
  { key: 'zipCode', text: 'Zip Code' },
  { key: 'county', text: 'County' },
  { key: 'email', text: 'Email' },
  { key: 'phone1', text: 'Phone1' },
  { key: 'phone2', text: 'Phone2' },
  { key: 'trackFor1099', text: 'Track For 1099' },
  { key: 'taxId', text: 'Tax Id' },
  { key: 'isDeceased', text: 'Is Deceased' },
  { key: 'isRemoved', text: 'Is Removed' },
  { key: 'specific', text: 'Specific' },
  { key: 'commonSource', text: 'Common Source' },
  { key: 'cardholderName', text: 'Cardholder Name' },
  { key: 'cardholderEmployer', text: 'Cardholder Employer' },
  { key: 'cardholderOccupation', text: 'Cardholder Occupation' },
  { key: 'committeeFecId', text: 'Committee FEC Id' },
  { key: 'fecId', text: 'FEC Id' },
  { key: 'candidateFecId', text: 'Candidate FEC Id' },
  { key: 'candidateFirstName', text: 'Candidate First Name' },
  { key: 'candidateMiddleName', text: 'Candidate Middle Name' },
  { key: 'candidateLastName', text: 'Candidate Last Name' },
  { key: 'candidateOffice', text: 'Candidate Office' },
  { key: 'candidateState', text: 'Candidate State' },
  { key: 'candidateDistrict', text: 'Candidate District' },
  {
    key: 'tags',
    text: 'Tags',
    renderFunc: item => {
      if (item?.length) {
        return item.join(', ');
      }
      return '';
    },
  },
  {
    key: 'notes',
    text: 'Notes',
    renderFunc: item => {
      if (item?.length) {
        return item.map(note => note.text).join(', ');
      }
      return '';
    },
  },
  { key: 'sourceDate', text: 'Source Date' },
  { key: 'purpose', text: 'Purpose' },
  // { key: 'lastContributionCreatedAt', text: 'Last Contribution CreatedAt' },
  // { key: 'lastContributionId', text: 'Last Contribution Id' },
  // { key: 'lastExpenditureCreatedAt', text: 'Last Expenditure CreatedAt' },
  // { key: 'lastExpenditureId', text: 'Last Expenditure Id' },
  { key: 'isCandidate', text: 'Is Candidate' },
  { key: 'contactId', text: 'Contact Id' },
  { key: 'createdAt', text: 'Created At' },
  { key: 'updatedAt', text: 'Updated At' },
  { key: 'createdBy', text: 'Created By' },
  { key: 'updatedBy', text: 'Updated By' },
];

export const initialState = {
  timePeriod: 0,
  startDate: '',
  endDate: '',
  filters: [...defaultFilters],
  columns: [],
  columnDefinitions: [],
  errors: {
    startDate: '',
    endDate: '',
  },
};

export const actions = {
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  SET_COLUMN_DEFINITIONS: 'SET_COLUMN_DEFINITIONS',
};

const calcFiltersWithDefaultFilters = calcFilters(defaultFilters);

export const actionMap = {
  [actions.HANDLE_CHANGE]: (state, { data: { fieldName, value } }) => {
    if (fieldName === 'timePeriod' && value !== 'custom') {
      return {
        ...state,
        [fieldName]: value,
        startDate: '',
        endDate: '',
        filters: calcFiltersWithDefaultFilters(fieldName, value, state),
      };
    }
    return {
      ...state,
      [fieldName]: value,
      filters: calcFiltersWithDefaultFilters(fieldName, value, state),
    };
  },
  [actions.SET_FORM_ERRORS]: (state, { data: { errors } }) => ({
    ...state,
    errors,
  }),
  [actions.SET_COLUMN_DEFINITIONS]: (state) => ({
    ...state,
    columns: (contactColumns || []).map(cd => cd.key),
    columnDefinitions: contactColumns,
  }),
};

export const contactsReducer = createReducer(
  initialState,
  actionMap,
);
