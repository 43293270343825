import { createReducer } from '../../reducers/createReducer';
import { deserializeDate } from '../../helpers/util';
import { sortDirections } from '../../helpers/constants';

export const initialState = {
  // coverpage
  electionDate: null,
  reportCode: 0,
  startDate: null,
  endDate: null,
  previousBalanceSource: '0',
  previousReportId: '0',
  textStatementText: '',
  continueEditMessageList: [],
  confirmContinueUpdateHidden: true,
  confirmContinueCallback: () => {},

  // Receipt query
  skip: 0,
  limit: 100,
  filterText: '',
  sortBy: '',
  sortDir: sortDirections.NONE,

  // summary page
  q6aColA: 0,
  q6aColB: 0,
  q6bColA: 0,
  q6bColB: 0,
  q7aColA: 0,
  q7aColB: 0,
  q7bColA: 0,
  q7bColB: 0,
  q8ColA: 0,
  q9ColA: 0,
  q10ColA: 0,

  // detailed summary receipts
  q11aiColA: 0,
  q11aiColB: 0,
  q11aiiColA: 0,
  q11aiiColB: 0,
  q11bColA: 0,
  q11bColB: 0,
  q11cColA: 0,
  q11cColB: 0,
  q11dColA: 0,
  q11dColB: 0,
  q12ColA: 0,
  q12ColB: 0,
  q13aColA: 0,
  q13aColB: 0,
  q13bColA: 0,
  q13bColB: 0,
  q14ColA: 0,
  q14ColB: 0,
  q15ColA: 0,
  q15ColB: 0,

  // detailed summary disbursements
  q17ColA: 0,
  q17ColB: 0,
  q18ColA: 0,
  q18ColB: 0,
  q19aColA: 0,
  q19aColB: 0,
  q19bColA: 0,
  q19bColB: 0,
  q20aColA: 0,
  q20aColB: 0,
  q20bColA: 0,
  q20bColB: 0,
  q20cColA: 0,
  q20cColB: 0,
  q21ColA: 0,
  q21ColB: 0,
  q23: 0,

  // general
  errors: {},
  expandedSection: 'coverpage',
  expandableSections: {
    coverpage: true,
    beginningBalances: false,
    summary: false,
    summaryDetailedReceipts: false,
    summaryDetailedDisbursements: false,
    itemizedReceipts: false,
    itemizedDisbursements: false,
    itemizedLoans: false,
    debts: false,
    f3Z: false,
    scheduleELines: false,
  },
  forceFilingWithWarnings: false,
  submitReportDialogHidden: true,
  isSubmittingFile: false,
  fileWithFecWarning: '',
  fecWebServiceFileWindow: null,
  submitFileWithWarnings: false,
  fileSuccessDialogHidden: true,
  previousBalanceSourceDialogHidden: true,
  isAutomaticConduit: false,
  usePostGeneralSummary: false,
  f3ZData: [],
  f3ZUpdated: 0,
};

export const actions = {
  ADD_F3Z_RECORD: 'ADD_F3Z_RECORD',
  UPDATE_F3Z_RECORD: 'UPDATE_F3Z_RECORD',
  DELETE_F3Z_RECORD: 'DELETE_F3Z_RECORD',
  FORM_ERRORS: 'FORM_ERRORS',
  SET_REPORT: 'SET_REPORT',
  CLEAR_REPORT_TYPE: 'CLEAR_REPORT_TYPE',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  HANDLE_PREVIOUS_BALANCE_CHANGE: 'HANDLE_PREVIOUS_BALANCE_CHANGE',
  TOGGLE_EXPAND_SECTION: 'TOGGLE_EXPAND_SECTION',
  SET_SORT: 'SET_SORT',
  SET_FILTER: 'SET_FILTER',
  OPEN_SUBMIT_REPORT_DIALOG: 'OPEN_SUBMIT_REPORT_DIALOG',
  CLOSE_SUBMIT_REPORT_DIALOG: 'CLOSE_SUBMIT_REPORT_DIALOG',
  PRESENT_FILING_SUCCESS_DIALOG: 'PRESENT_FILING_SUCCESS_DIALOG',
  CLOSE_FILING_SUCCESS_DIALOG: 'CLOSE_FILING_SUCCESS_DIALOG',
  FORCE_FEDERAL_FILING_WITH_WARNINGS: 'FORCE_FEDERAL_FILING_WITH_WARNINGS',
  IS_SUBMITTING_FILE: 'IS_SUBMITTING_FILE',
  SET_FEC_FILING_WARNINGS: 'SET_FEC_FILING_WARNINGS',
  SET_FEC_WEB_SERVICE_PDF_WINDOW: 'SET_FEC_WEB_SERVICE_PDF_WINDOW',
  TOGGLE_SUBMIT_WITH_WARNINGS: 'TOGGLE_SUBMIT_WITH_WARNINGS',
  PRESENT_SET_PREVIOUS_BALANCE_SOURCE: 'PRESENT_SET_PREVIOUS_BALANCE_SOURCE',
  CLOSE_SET_PREVIOUS_BALANCE_SOURCE: 'CLOSE_SET_PREVIOUS_BALANCE_SOURCE',
  SHOW_CONTINUE_UPDATE: 'SHOW_CONTINUE_UPDATE',
  HIDE_CONTINUE_UPDATE: 'HIDE_CONTINUE_UPDATE',
};

export const actionMap = {
  [actions.FORM_ERRORS]: (state, action) => {
    return {
      ...state,
      errors: action.data.errors,
    };
  },
  [actions.SET_REPORT]: (state, action) => {
    const { report, frCampaignType } = action.data;
    if (Object.keys(report).length === 0) {
      return state;
    }

    const {
      fecPacSummaryPreviousBalanceAmounts,
      fecPacSummary,
      f3ZData,
      fecSummary: {
        colA_6a_TotalContributionsNoLoans,
        colA_6b_TotalContributionRefunds,
        colA_7a_TotalOperatingExpenditures,
        colA_7b_TotalOffsetsToOperatingExpenditures,
        colA_8_CashOnHandAtClose,
        colA_9_DebtsTo_TotalsFromSchCAndOrD,
        colA_10_DebtsBy_TotalsFromSchCAndOrD,
        colA_11ai_IndividualsItemized,
        colA_11aii_IndividualsUnitemized,
        colA_11b_PoliticalPartyCommittees,
        colA_11c_OtherPoliticalCommittees,
        colA_11d_TheCandidate,
        colA_12_TransfersFromOtherAuthorizedCommittees,
        colA_13a_LoansMadeOrGuarnByTheCandidate,
        colA_13b_AllOtherLoans,
        colA_14_OffsetsToOperatingExpenditures,
        colA_15_OtherReceipts,
        colA_17_OperatingExpenditures,
        colA_18_TransfersToOtherAuthorizedCommittees,
        colA_19a_LoanRepaymentByCandidate,
        colA_19b_LoanRepayments_AllOtherLoans,
        colA_20a_Refund_IndividualsOtherThanPolCmtes,
        colA_20b_Refund_PoliticalPartyCommittees,
        colA_20c_Refund_OtherPoliticalCommittees,
        colA_21_OtherDisbursements,
        colA_23_CashBeginningReportingPeriod,
        colB_6a_TotalContributionsNoLoans,
        colB_6b_TotalContributionRefunds,
        colB_7a_TotalOperatingExpenditures,
        colB_7b_TotalOffsetsToOperatingExpenditures,
        colB_11ai_IndividualsItemized,
        colB_11aii_IndividualsUnitemized,
        colB_11b_PoliticalPartyCommittees,
        colB_11c_OtherPoliticalCommittees,
        colB_11d_TheCandidate,
        colB_12_TransfersFromOtherAuthorizedCommittees,
        colB_13a_LoansMadeOrGuarnByTheCandidate,
        colB_13b_AllOtherLoans,
        colB_14_OffsetsToOperatingExpenditures,
        colB_15_OtherReceipts,
        colB_17_OperatingExpenditures,
        colB_18_TransfersToOtherAuthorizedCommittees,
        colB_19a_LoanRepaymentByCandidate,
        colB_19b_LoanRepayments_AllOtherLoans,
        colB_20a_Refund_IndividualsOtherThanPolCmtes,
        colB_20b_Refund_PoliticalPartyCommittees,
        colB_20c_Refund_OtherPoliticalCommittees,
        colB_21_OtherDisbursements,
      },
    } = report;

    const { textStatement, _id } = report;
    return {
      ...state,
      frCampaignType,
      fecPacSummaryPreviousBalanceAmounts,
      fecPacSummary,
      f3ZData,
      _id,
      electionDate: deserializeDate(report.electionDate),
      reportType: report.reportType,
      reportCode: report.reportCode,
      electionCycle: report.electionCycle,
      startDate: deserializeDate(report.startDate),
      endDate: deserializeDate(report.endDate),
      previousReportId: report.previousReportId,
      previousBalanceSource: report.previousBalanceSource,
      textStatementText: textStatement ? textStatement.text : '',
      usePostGeneralSummary: report.usePostGeneralSummary || false,
      isAutomaticConduit: report.isAutomaticConduit,
      q6aColA: colA_6a_TotalContributionsNoLoans,
      q6aColB: colB_6a_TotalContributionsNoLoans,
      q6bColA: colA_6b_TotalContributionRefunds,
      q6bColB: colB_6b_TotalContributionRefunds,
      q7aColA: colA_7a_TotalOperatingExpenditures,
      q7aColB: colB_7a_TotalOperatingExpenditures,
      q7bColA: colA_7b_TotalOffsetsToOperatingExpenditures,
      q7bColB: colB_7b_TotalOffsetsToOperatingExpenditures,
      q8ColA: colA_8_CashOnHandAtClose,
      q9ColA: colA_9_DebtsTo_TotalsFromSchCAndOrD,
      q10ColA: colA_10_DebtsBy_TotalsFromSchCAndOrD,

      // detailed summary receipts
      q11aiColA: colA_11ai_IndividualsItemized,
      q11aiColB: colB_11ai_IndividualsItemized,
      q11aiiColA: colA_11aii_IndividualsUnitemized,
      q11aiiColB: colB_11aii_IndividualsUnitemized,
      q11bColA: colA_11b_PoliticalPartyCommittees,
      q11bColB: colB_11b_PoliticalPartyCommittees,
      q11cColA: colA_11c_OtherPoliticalCommittees,
      q11cColB: colB_11c_OtherPoliticalCommittees,
      q11dColA: colA_11d_TheCandidate,
      q11dColB: colB_11d_TheCandidate,
      q12ColA: colA_12_TransfersFromOtherAuthorizedCommittees,
      q12ColB: colB_12_TransfersFromOtherAuthorizedCommittees,
      q13aColA: colA_13a_LoansMadeOrGuarnByTheCandidate,
      q13aColB: colB_13a_LoansMadeOrGuarnByTheCandidate,
      q13bColA: colA_13b_AllOtherLoans,
      q13bColB: colB_13b_AllOtherLoans,
      q14ColA: colA_14_OffsetsToOperatingExpenditures,
      q14ColB: colB_14_OffsetsToOperatingExpenditures,
      q15ColA: colA_15_OtherReceipts,
      q15ColB: colB_15_OtherReceipts,

      // detailed summary disbursements
      q17ColA: colA_17_OperatingExpenditures,
      q17ColB: colB_17_OperatingExpenditures,
      q18ColA: colA_18_TransfersToOtherAuthorizedCommittees,
      q18ColB: colB_18_TransfersToOtherAuthorizedCommittees,
      q19aColA: colA_19a_LoanRepaymentByCandidate,
      q19aColB: colB_19a_LoanRepaymentByCandidate,
      q19bColA: colA_19b_LoanRepayments_AllOtherLoans,
      q19bColB: colB_19b_LoanRepayments_AllOtherLoans,
      q20aColA: colA_20a_Refund_IndividualsOtherThanPolCmtes,
      q20aColB: colB_20a_Refund_IndividualsOtherThanPolCmtes,
      q20bColA: colA_20b_Refund_PoliticalPartyCommittees,
      q20bColB: colB_20b_Refund_PoliticalPartyCommittees,
      q20cColA: colA_20c_Refund_OtherPoliticalCommittees,
      q20cColB: colB_20c_Refund_OtherPoliticalCommittees,
      q21ColA: colA_21_OtherDisbursements,
      q21ColB: colB_21_OtherDisbursements,
      q23: colA_23_CashBeginningReportingPeriod,
    };
  },
  [actions.HANDLE_CHANGE]: (state, action) => ({
    ...state,
    [action.data.fieldName]: action.data.value,
  }),
  [actions.ADD_F3Z_RECORD]: (state, action) => {
    const f3ZData = [...state.f3ZData, action.data];
    const newState = {
      ...state,
      f3ZData,
      f3ZUpdated: state.f3ZUpdated + 1,
    };
    return newState;
  },
  [actions.UPDATE_F3Z_RECORD]: (state, action) => {
    const f3ZData = [...state.f3ZData].reduce((a, o) => {
      if (o._id === action.data._id) {
        a.push(action.data);
      } else {
        a.push(o);
      }
      return a;
    }, []);
    const newState = {
      ...state,
      f3ZData,
      f3ZUpdated: state.f3ZUpdated + 1,
    };
    return newState;
  },
  [actions.DELETE_F3Z_RECORD]: (state, action) => {
    const f3ZData = [...state.f3ZData].reduce((a, o) => {
      if (o._id !== action.data._id) {
        a.push(o);
      }
      return a;
    }, []);
    const newState = {
      ...state,
      f3ZData,
      f3ZUpdated: state.f3ZUpdated + 1,
    };
    return newState;
  },
  [actions.HANDLE_PREVIOUS_BALANCE_CHANGE]: (state, action) => {
    return {
      ...state,
      fecPacSummaryPreviousBalanceAmounts: {
        ...state.fecPacSummaryPreviousBalanceAmounts,
        [action.data.fieldName]: action.data.value,
      },
    };
  },
  [actions.SET_SORT]: (state, action) => ({
    ...state,
    sortBy: action.data.sortBy,
    sortDir: action.data.sortDir,
    skip: 0,
  }),
  [actions.SET_FILTER]: (state, action) => ({
    ...state,
    filterText: action.data.filterText,
    skip: 0,
  }),
  [actions.CLEAR_REPORT_TYPE]: (state) => ({
    ...state,
    reportType: null,
  }),
  [actions.TOGGLE_EXPAND_SECTION]: (state, action) => {
    return {
      ...state,
      expandedSection: action.data.sectionName,
      expandableSections: Object.entries(state.expandableSections).reduce(
        (acc, section) => {
          const [key, value] = section;
          if (action.data.sectionName === key) {
            acc[key] = !value;
          } else {
            acc[key] = false;
          }
          return acc;
        },
        {},
      ),
    };
  },
  [actions.OPEN_SUBMIT_REPORT_DIALOG]: state => ({
    ...state,
    submitReportDialogHidden: false,
  }),
  [actions.CLOSE_SUBMIT_REPORT_DIALOG]: state => ({
    ...state,
    isSubmittingFile: false,
    fileWithFecWarning: '',
    submitReportDialogHidden: true,
  }),
  [actions.PRESENT_FILING_SUCCESS_DIALOG]: state => ({
    ...state,
    isSubmittingFile: false,
    fileWithFecWarning: '',
    fileSuccessDialogHidden: false,
  }),
  [actions.CLOSE_FILING_SUCCESS_DIALOG]: state => ({
    ...state,
    isSubmittingFile: false,
    fileWithFecWarning: '',
    fileSuccessDialogHidden: true,
  }),
  [actions.PRESENT_SET_PREVIOUS_BALANCE_SOURCE]: state => ({
    ...state,
    previousBalanceSourceDialogHidden: false,
  }),
  [actions.CLOSE_SET_PREVIOUS_BALANCE_SOURCE]: state => ({
    ...state,
    previousBalanceSourceDialogHidden: true,
  }),
  [actions.FORCE_FEDERAL_FILING_WITH_WARNINGS]: state => ({
    ...state,
    forceFilingWithWarnings: true,
  }),
  [actions.IS_SUBMITTING_FILE]: state => ({
    ...state,
    isSubmittingFile: true,
  }),
  [actions.SET_FEC_FILING_WARNINGS]: (state, action) => ({
    ...state,
    isSubmittingFile: false,
    fileWithFecWarning: action.data.fileWithFecWarning,
  }),
  [actions.SET_FEC_WEB_SERVICE_PDF_WINDOW]: (state, action) => {
    return { ...state, fecWebServiceFileWindow: action.data };
  },
  [actions.TOGGLE_SUBMIT_WITH_WARNINGS]: (state) => {
    return { ...state, submitFileWithWarnings: !state.submitFileWithWarnings };
  },
  [actions.SHOW_CONTINUE_UPDATE]: (
    state,
    { data: { continueEditMessageList, confirmContinueCallback } },
  ) => ({
    ...state,
    confirmContinueUpdateHidden: false,
    confirmContinueCallback,
    continueEditMessageList,
  }),
  [actions.HIDE_CONTINUE_UPDATE]: (
    state,
  ) => ({
    ...state,
    confirmContinueUpdateHidden: true,
    confirmContinueCallback: null,
  }),
};

export const fecReportReducer = createReducer(initialState, actionMap);
