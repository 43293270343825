import moment from 'moment';
import { getLabel } from './labelHelper';
import { sortDirections } from './constants';

export const applyFilter = (filters, propName) => item =>
  filters.length > 0 ? filters.includes(item[propName]) : true;

export const applyTagsFilter = filters => item => {
  if (filters?.length > 0 && item?.tags?.length === 0) {
    return false;
  }

  if (filters?.length > 0 && item?.tags?.length > 0) {
    const result = item?.tags.reduce((hasTag, tag) => {
      if (filters.includes(tag)) {
        hasTag = true;
      }
      return hasTag;
    }, false);

    return result;
  }

  return true;
};

export const byMinDate = (date, propName) => item => {
  if (date) {
    const itemDate = new Date(item[propName]);
    return date.getTime() <= itemDate.getTime();
  }
  return true;
};

export const byMaxDate = (date, propName) => item => {
  if (date) {
    const itemDate = new Date(item[propName]);
    return date.getTime() >= itemDate.getTime();
  }
  return true;
};

export const sortByUpdatedAtNewestFirst = (l) => {
  return l.sort((o1, o2) => {
    const a = moment(o1.updatedAt);
    const b = moment(o2.updatedAt);
    return a.isSameOrBefore(b) ? 1 : -1;
  });
};

export const byMinAmount = (minAmount, propName) => item => {
  const minLimit = parseFloat(minAmount);
  const itemAmount = parseFloat(item[propName].replace(/[^0-9\\.]/g, ''));
  return minAmount ? minLimit <= itemAmount : true;
};

export const byMaxAmount = (maxAmount, propName) => item => {
  const maxLimit = parseFloat(maxAmount);
  const itemAmount = parseFloat(item[propName].replace(/[^0-9\\.]/g, ''));
  return maxAmount && maxLimit !== 0 ? maxLimit >= itemAmount : true;
};

export function onColumnClick(field) {
  return state => {
    if (state.sortField === field) {
      if (state.sortDirection === sortDirections.ASC) {
        state.sortDirection = sortDirections.DESC;
      } else if (state.sortDirection === sortDirections.DESC) {
        state.sortDirection = sortDirections.NONE;
      } else {
        state.sortDirection = sortDirections.ASC;
      }
    } else {
      state.sortField = field;
      state.sortDirection = sortDirections.ASC;
    }
    return state;
  };
}

export function searchValueForTerm(value, searchTerm) {
  let val = value;
  if (val === undefined || val === null) {
    return false;
  }
  if (searchTerm) {
    if (typeof val === 'number') {
      val = val.toFixed(2).toString();
    }
    return val.toLowerCase().includes(searchTerm);
  }

  return true;
}

export const columns = args => [
  { key: 'addressLine1', text: 'Address Line 1' },
  { key: 'addressLine2', text: 'Address Line 2' },
  { key: 'aggregateAmount', text: 'Aggregate Amount' },
  { key: 'amount', text: 'Amount' },
  { key: 'cardholderEmployer', text: 'Cardholder Employer' },
  { key: 'cardholderName', text: 'Cardholder Name' },
  { key: 'cardholderOccupation', text: 'Cardholder Occupation' },
  { key: 'checkNumber', text: 'Check Number' },
  { key: 'city', text: 'City' },
  { key: 'conduitName', text: 'Conduit Name' },
  { key: 'contactName', text: 'Contact Name' },
  { key: 'county', text: 'County' },
  { key: 'createdAt', text: 'Created At' },
  { key: 'creditCardLast4', text: 'Credit Card Last 4' },
  { key: 'creditCardType', text: 'Credit Card Type' },
  { key: 'electionCycle', text: 'Election' },
  { key: 'electionYear', text: 'Election Year' },
  { key: 'email', text: 'Email' },
  { key: 'employer', text: 'Employer' },
  { key: 'entityType', text: 'Entity Type' },
  { key: 'expenditureDate', text: 'Expenditure Date' },
  { key: 'fecFormType', text: 'FEC Form Type' },
  { key: 'fiduciaryResponsibility', text: 'Fiduciary Responsibility' },
  { key: 'firstName', text: 'First Name' },
  { key: 'address', text: 'Full Address' },
  { key: 'displayName', text: 'Full Name' },
  { key: 'lastName', text: 'Last Name' },
  { key: 'memoCode', text: 'Memo Code' },
  { key: 'memoText', text: 'Memo Text' },
  { key: 'middleName', text: 'Middle Name' },
  { key: 'occupation', text: 'Occupation' },
  { key: 'businessName', text: 'Organization Name' },
  { key: 'businessType', text: 'Organization Type' },
  { key: 'paymentReferenceId', text: 'Payment Reference Id' },
  { key: 'paymentType', text: 'Payment Type' },
  { key: 'phone1', text: 'Phone 1' },
  { key: 'phone2', text: 'Phone 2' },
  { key: 'purpose', text: 'Purpose' },
  { key: 'contactType', text: 'Contact Type' },
  { key: 'state', text: 'State' },
  {
    key: 'tags',
    text: 'Tags',
    renderFunc: item => {
      if (item?.length) {
        return item.join(', ');
      }
      return '';
    },
  },
  { key: 'transactionDate', text: 'Transaction Date' },
  { key: 'transactionId', text: 'Transaction Id' },
  { key: 'transactionIdNumber', text: 'Transaction Id Number' },
  { key: 'transactionKind', text: 'Transaction Kind' },
  {
    key: 'transactionType',
    text: 'Transaction Type',
    renderFunc: item => getLabel(item, args.session),
  },
  { key: 'description', text: 'Description' },
  { key: 'updatedAt', text: 'Updated At' },
  { key: 'zipCode', text: 'Zip Code' },
];

// TODO: remove after lists go live
export const standardColumns = args => [
  { key: 'transactionId', text: 'Transaction Id' },
  {
    key: 'transactionType',
    text: 'Transaction Type',
    renderFunc: item => getLabel(item, args.session),
  },
  { key: 'transactionKind', text: 'Transaction Kind' },
  { key: 'firstName', text: 'First Name' },
  { key: 'middleName', text: 'Middle Name' },
  { key: 'lastName', text: 'Last Name' },
  { key: 'displayName', text: 'Full Name' },
  { key: 'entityType', text: 'Entity Type' },
  { key: 'businessName', text: 'Business/Committee Name' },
  { key: 'contactName', text: 'Contact Name' },
  { key: 'committeeName', text: 'Committee Name' },
  {
    key: 'transactionDate',
    text: 'Transaction Date',
  },
  { key: 'amount', text: 'Amount' },
  { key: 'paymentType', text: 'Payment Type' },
  { key: 'paymentReferenceId', text: 'Payment Reference Id' },
  { key: 'description', text: 'Description' },
  { key: 'cardholderName', text: 'Cardholder Name' },
  { key: 'creditCardCompany', text: 'Credit Card Company' },
  { key: 'creditCardType', text: 'Credit Card Type' },
  { key: 'creditCardLast4', text: 'Credit Card Last 4' },
  { key: 'checkNumber', text: 'Check Number' },
  { key: 'occupation', text: 'Occupation' },
  { key: 'employer', text: 'Employer' },
  { key: 'email', text: 'Email' },
  { key: 'phone1', text: 'Phone 1' },
  { key: 'phone2', text: 'Phone 2' },
  { key: 'address', text: 'Full Address' },
  { key: 'addressLine1', text: 'Address Line 1' },
  { key: 'addressLine2', text: 'Address Line 2' },
  { key: 'city', text: 'City' },
  { key: 'state', text: 'State' },
  { key: 'zipCode', text: 'Zip Code' },
  { key: 'county', text: 'County' },
  { key: 'electionYear', text: 'Election Year' },
  { key: 'electionCycle', text: 'Election' },
  { key: 'createdAt', text: 'Created At' },
  { key: 'updatedAt', text: 'Updated At' },
  { key: 'cardholderEmployer', text: 'Cardholder Employer' },
  { key: 'cardholderOccupation', text: 'Cardholder Occupation' },
  { key: 'fiduciaryResponsibility', text: 'Fiduciary Responsibility' },
  {
    key: 'tags',
    text: 'Tags',
    renderFunc: item => {
      if (item?.length) {
        return item.join(', ');
      }
      return '';
    },
  },
];

export const endRecipientColumns = () => [
  { key: 'displayName', text: 'Full Name' },
  { key: 'address', text: 'Address' },
  { key: 'amount', text: 'Amount' },
  { key: 'businessType', text: 'Business Type' },
  { key: 'contactName', text: 'Contact Name' },
  { key: 'email', text: 'Email Address' },
  {
    key: 'expenditureDate',
    text: 'Expenditure Date',
  },
  { key: 'phone1', text: 'Phone 1' },
  { key: 'phone2', text: 'Phone 2' },
  { key: 'purpose', text: 'Purpose' },
  { key: 'contactType', text: 'Recipient Type' },
  {
    key: 'tags',
    text: 'Tags',
    renderFunc: item => {
      if (item?.length) {
        return item.join(', ');
      }
      return '';
    },
  },
];

export const listOptions = [
  {
    key: 0,
    text: 'Select Column',
  },
  {
    key: 'amount',
    text: 'Amount',
  },
  {
    key: 'aggregateAmount',
    text: 'Aggregate Amount',
  },
  {
    key: 'conduitName',
    text: 'Conduit Name',
  },
  {
    key: 'electionCycle',
    text: 'Election',
  },
  {
    key: 'entityType',
    text: 'Entity Type',
  },
  {
    key: 'displayName',
    text: 'Full Name',
  },
  {
    key: 'organizationName',
    text: 'Organization Name',
  },
  {
    key: 'paymentType',
    text: 'Payment Type',
  },
  {
    key: 'tags',
    text: 'Tags',
  },
  {
    key: 'transactionDate',
    text: 'Transaction Date',
  },
  {
    key: 'transactionKind',
    text: 'Transaction Kind',
  },
  {
    key: 'transactionType',
    text: 'Transaction Type',
  },
];

export const timePeriodFilters = [
  {
    key: 0,
    text: 'Select',
  },
  {
    key: 'today',
    text: 'Today',
  },
  {
    key: 'week',
    text: 'Last 7 days',
  },
  {
    key: 'month',
    text: 'Last 30 days',
  },
  {
    key: 'threeMonths',
    text: 'Last 90 days',
  },
  {
    key: 'custom',
    text: 'Custom Date Range',
  },
];

export const timePeriodFilterOnlyCustom = [
  {
    key: 'custom',
    text: 'Custom Date Range',
  },
];

export const rangeMap = {
  today: 1,
  week: 7,
  month: 30,
  threeMonths: 90,
};

const calcStartDate = offset => {
  const today = new Date();
  const last = new Date(today.getTime() - offset * 24 * 60 * 60 * 1000);
  const day = last.getDate();
  const month = last.getMonth();
  const year = last.getFullYear();
  return { year, month, day };
};

const inList = (list, filter) => {
  return list.slice().filter(f => {
    return (filter.column === f.column) && (filter.expression === f.expression);
  }).length > 0;
};

export const uniqueFilters = filters => {
  const fltr = filters.slice().reverse().reduce((a, o) => {
    if (!inList(a, o)) {
      a.push(o);
    }
    return a;
  }, []);
  return fltr.filter(f => f.value);
};

export const calcFilters = defaultFilters => (fieldName, value, state) => {
  if ((fieldName === 'startDate' || fieldName === 'endDate') && !value) {
    return [...defaultFilters];
  }

  if (fieldName === 'timePeriod' && value !== 'custom') {
    const days = rangeMap[value];
    if (days) {
      const { year, month, day } = calcStartDate(days);
      const dateFilter = {
        column: 'transactionDate',
        expression: 'isGreaterThan',
        value: new Date(year, month, day),
      };
      return [...defaultFilters, dateFilter];
    }

    return [...defaultFilters];
  }

  if (fieldName === 'startDate') {
    const endDateFilter = state.filters.find(
      f => f.expression === 'isLessThanOrEqual',
    );
    if (endDateFilter) {
      return [
        ...defaultFilters,
        ...state.filters,
        {
          column: 'transactionDate',
          expression: 'isGreaterThanOrEqual',
          value,
        },
        endDateFilter,
      ];
    }
    return [
      ...defaultFilters,
      ...state.filters,
      {
        column: 'transactionDate',
        expression: 'isGreaterThanOrEqual',
        value,
      },
    ];
  }

  if (fieldName === 'endDate') {
    const startDateFilter = state.filters.find(
      f => f.expression === 'isGreaterThanOrEqual',
    );
    if (startDateFilter) {
      return [
        ...defaultFilters,
        ...state.filters,
        {
          column: 'transactionDate',
          expression: 'isLessThanOrEqual',
          value,
        },
        startDateFilter,
      ];
    }
    return [
      ...defaultFilters,
      ...state.filters,
      {
        column: 'transactionDate',
        expression: 'isLessThanOrEqual',
        value,
      },
    ];
  }

  if (fieldName === 'missingPurpose') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'missingPurpose',
        expression: 'customFilter',
        value,
      },
    ]);
  }

  return state.filters;
};

export const calcContributionByDateRangeFilters = defaultFilters => (fieldName, value, state) => {
  if (fieldName === 'startDate') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'transactionDate',
        expression: 'isGreaterThanOrEqual',
        value,
      },
    ]);
  }

  if (fieldName === 'endDate') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'transactionDate',
        expression: 'isLessThanOrEqual',
        value,
      },
    ]);
  }

  if (fieldName === 'startAmount') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'amount',
        expression: 'isGreaterThanOrEqual',
        value,
      },
    ]);
  }

  if (fieldName === 'endAmount') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'amount',
        expression: 'isLessThanOrEqual',
        value,
      },
    ]);
  }

  if (fieldName === 'startAggregateAmount') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'aggregateAmount',
        expression: 'isGreaterThanOrEqual',
        value,
      },
    ]);
  }

  if (fieldName === 'endAggregateAmount') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'aggregateAmount',
        expression: 'isLessThanOrEqual',
        value,
      },
    ]);
  }

  if (fieldName === 'state') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'address.state',
        expression: 'equals',
        value,
      },
    ]);
  }

  if (fieldName === 'tags') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'tags',
        expression: 'equals',
        value,
      },
    ]);
  }

  if (fieldName === 'noEmployeeOrOccupation') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'noEmployeeOrOccupation',
        expression: 'customFilter',
        value,
      },
    ]);
  }

  if (fieldName === 'isJointContribution') {
    return uniqueFilters([
      ...defaultFilters,
      ...state.filters,
      {
        column: 'isJointContribution',
        expression: 'customFilter',
        value,
      },
    ]);
  }

  return uniqueFilters([
    ...defaultFilters,
    ...state.filters,
    {
      column: fieldName,
      expression: 'equals',
      value,
    },
  ]);
};

export const filterExpressions = [
  {
    key: 0,
    text: 'Select Expression',
  },
  {
    key: 'exists',
    text: 'Exists',
    data: {
      validFor: [
        'amount',
        'aggregateAmount',
        'electionCycle',
        'entityType',
        'displayName',
        'organizationName',
        'paymentType',
        'transactionType',
        'conduitName',
      ],
    },
  },
  {
    key: 'doesNotExist',
    text: 'Does not exist',
    data: {
      validFor: [
        'amount',
        'aggregateAmount',
        'electionCycle',
        'entityType',
        'displayName',
        'organizationName',
        'paymentType',
        'transactionType',
        'conduitName',
      ],
    },
  },
  {
    key: 'equals',
    text: 'Equals',
    data: {
      validFor: [
        'amount',
        'aggregateAmount',
        'electionCycle',
        'entityType',
        'displayName',
        'organizationName',
        'paymentType',
        'tags',
        'transactionDate',
        'transactionType',
        'transactionKind',
        'conduitName',
      ],
    },
  },
  {
    key: 'notEquals',
    text: 'Does not equal',
    data: {
      validFor: [
        'amount',
        'aggregateAmount',
        'electionCycle',
        'entityType',
        'displayName',
        'organizationName',
        'paymentType',
        'tags',
        'transactionDate',
        'transactionType',
        'transactionKind',
        'conduitName',
      ],
    },
  },
  {
    key: 'isOneOf',
    text: 'Is one of',
    data: {
      validFor: [
        'electionCycle',
        'entityType',
        'paymentType',
        'tags',
        'transactionType',
        'transactionKind',
      ],
    },
  },
  {
    key: 'isNotOneOf',
    text: 'Is not one of',
    data: {
      validFor: [
        'electionCycle',
        'entityType',
        'paymentType',
        'tags',
        'transactionType',
        'transactionKind',
      ],
    },
  },
  {
    key: 'contains',
    text: 'Contains',
    data: {
      validFor: ['displayName', 'organizationName', 'conduitName'],
    },
  },
  {
    key: 'isGreaterThan',
    text: '>',
    data: {
      validFor: ['amount', 'transactionDate', 'aggregateAmount'],
    },
  },
  {
    key: 'isGreaterThanOrEqual',
    text: '>=',
    data: {
      validFor: ['amount', 'transactionDate', 'aggregateAmount'],
    },
  },
  {
    key: 'isLessThan',
    text: '<',
    data: {
      validFor: ['amount', 'transactionDate', 'aggregateAmount'],
    },
  },
  {
    key: 'isLessThanOrEqual',
    text: '<=',
    data: {
      validFor: ['amount', 'transactionDate', 'aggregateAmount'],
    },
  },
];
