import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Stack,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  Text,
} from 'office-ui-fabric-react';
import { BackButton } from '../../../components/common';
import {
  getUserSession,
  getListsForAdHoc,
  getListFetchStatus,
  getListRecordCount,
  getListCSVStatus,
} from '../../../selectors';

import {
  contactsReducer,
  initialState,
  actions,
} from './contactsReducer';
import { types as listActions } from '../../../actions/listActions';
import AdHocResultsList from '../AdHocResultsList';
import { formatISODate } from '../../../helpers/util';
import { statuses } from '../../../helpers/constants';
import '../SpecificList.css';

const styles = mergeStyleSets({
  list: {
    height: 'calc(100vh - 245px)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  listBlurb: {
    fontSize: '20px',
    marginLeft: 80,
  },
});

const Contacts = ({ history }) => {
  const [state, localDispatch] = useReducer(
    contactsReducer,
    initialState,
  );

  const reduxDispatch = useDispatch();
  const session = useSelector(getUserSession);
  const lists = useSelector(getListsForAdHoc);
  const listFetchStatus = useSelector(getListFetchStatus);
  const totalRecords = useSelector(getListRecordCount);
  const listCSVStatus = useSelector(getListCSVStatus);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (state.columns.length > 0) {
      reduxDispatch({
        type: listActions.GET_LIST,
        data: {
          fields: 'contacts-fields',
          filters: JSON.stringify(
            state.filters.reduce((list, filter) => {
              const { column, expression } = filter;
              let { value } = filter;
              if (column === 'transactionDate') {
                value = formatISODate(value);
              }
              list = [
                ...list,
                {
                  column,
                  expression,
                  value: Array.isArray(value)
                    ? value.join(',')
                    : column === 'amount' || column === 'aggregateAmount'
                    ? +value
                    : value + '',
                },
              ];
              return list;
            }, []),
          ),
        },
      });
    }
  }, [state.filters, state.columns]);

  const getColumns = async () => {
    localDispatch({
      type: actions.SET_COLUMN_DEFINITIONS,
    });
  };

  useEffect(() => {
    getColumns();
    return () => {
      reduxDispatch({
        type: listActions.CLEAR_LISTS,
      });
    };
  }, []);

  useEffect(() => {
    if (lists.length && state.columns) {
      setItems(lists);
    }
  }, [lists, state.columns]);

  const exportToCSV = async () => {
    reduxDispatch({
      type: listActions.GET_CSV,
      data: {
        fields: 'contacts-fields',
        filters: JSON.stringify(
          state.filters.reduce((list, filter) => {
            const { column, expression } = filter;
            let { value } = filter;
            if (column === 'transactionDate') {
              value = formatISODate(value);
            }
            list = [
              ...list,
              {
                column,
                expression,
                value: Array.isArray(value)
                  ? value.join(',')
                  : column === 'amount' || column === 'aggregateAmount'
                  ? +value
                  : value + '',
              },
            ];
            return list;
          }, []),
        ),
      },
    });
  };

  return (
    <>
      <BackButton
        history={history}
        isFixed
        url="/filer/listsIndex"
        pageTitle="Contacts"
      />
      <div className="AdhocContacts SpecificList">
        {listCSVStatus === statuses.PROCESSING ? (
          <div className="list loading-csv">
            <Spinner size={SpinnerSize.large} label="Generating CSV" />
          </div>
        ) : (
          <div className="list adhoc-contact-margin-right">
            <Stack childrenGap={8}>
              <Stack
                horizontal
                verticalAlign="center"
                childrenGap={8}
                className={styles.topRow}
              >
                <ActionButton
                  text={`Export to CSV (${totalRecords} records)`}
                  onClick={exportToCSV}
                  disabled={lists.length === 0 || state.columns.length === 0}
                  iconProps={{
                    iconName: 'FileCSV',
                  }}
                />
                {totalRecords > 10 && (
                  <Text variant="small">Previewing first 10 records</Text>
                )}
              </Stack>
              <Stack childrenGap={8} className={styles.list}>
                {listFetchStatus === statuses.PROCESSING && (
                  <div className="loading-list">
                    <Spinner size={SpinnerSize.large} label="Loading..." />
                  </div>
                )}
                {lists.length > 0 && listFetchStatus === statuses.SUCCESS && (
                  <AdHocResultsList
                    session={session}
                    items={items}
                    selectedColumns={state.columns}
                    customColumnList={state.columnDefinitions}
                  />
                )}
                {lists.length === 0 && listFetchStatus === statuses.SUCCESS && (
                  <p className={styles.listBlurb}>No results found</p>
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </div>
    </>
  );
};

Contacts.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Contacts;
