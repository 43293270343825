import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, IconButton, NormalPeoplePicker, Persona, BasePicker } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { ActionButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';
import {
  IndividualForm,
  BusinessForm,
  CommonSourceForm,
  CommitteeForm,
  CandidateCommitteeForm,
  PacMulticandidateForm,
  PacNonMulticandidateForm,
  PartyCommitteeSDLForm,
  PartyCommitteeNationalForm,
  CandidateCampaignCommitteeForm,
  CandidateForm,
  TrustForm,
} from './EntityForms';
import { AddressForm } from './AddressForm';
import { EmailForm } from './EmailForm';
import { PhoneForm } from './PhoneForm';
import { FamilyForm } from './FamilyForm';
import { SectionSeparator } from './SectionSeparator';
import { getOptions as getContactOptions } from '../../helpers/contactHelper';
import { useContactSearch } from '../../hooks/useContactSearch';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import Tip from '../common/Tip';
import { SourceTypePicker, GAOccupationPicker } from '../Pickers';

class AddressBasePicker extends BasePicker { }

const stackTokens = {
  padding: 10,
};

const ContactForm = ({ session, contactState, actions, campaign }) => {
  const contactTypeOptions = getContactOptions(session);

  const addressSearchSelectionEdit = actions.handleChange('addressSearchSelection');
  const addressLine1Edit = actions.handleChange('addressLine1');
  const cityEdit = actions.handleChange('city');
  const stateCodeEdit = actions.handleChange('state');
  const countyEdit = actions.handleChange('county');
  const zipEdit = actions.handleChange('zipCode');

  const removeAddress = () => {
    addressSearchSelectionEdit(null, []);
    addressLine1Edit(null, '');
    cityEdit(null, '');
    stateCodeEdit(null, { key: 0 });
    countyEdit(null, '');
    zipEdit(null, '');
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(null, null, null, null);
  const [onResolveAddressSuggestions, onRenderAddressSuggestionsItem, renderAddressItem] = useAddressSearch(removeAddress);

  const onRenderItem = itemProps => {
    const { displayName, _id } = itemProps.item;
    // const { displayName, _id } = itemProps.item;
    //
    // const idLabel = _id ? `(Id: ...${_id.substring(_id.length - 4, _id.length + 1)})` : '';
    // const name = `${displayName} ${idLabel}`;
    return (
      <div className="chosen-household" key={_id}>
        <Persona text={displayName} size={10} />
        <IconButton
          style={{ height: 16, width: 16 }}
          iconProps={{ iconName: 'Times' }}
          onClick={() => actions.householdMembers.onHouseholdMemberRemoved(itemProps.onRemoveItem, _id)}
        />
      </div>
    );
  };

  const onAddressItemSelect = item => {
    addressLine1Edit(null, item?.address_line1 || '');
    cityEdit(null, item?.city || '');
    stateCodeEdit(null, { key: item?.state_code || 0 });
    countyEdit(null, item?.county || '');
    zipEdit(null, item?.postcode || '');
    addressSearchSelectionEdit(null, [item?.formatted]);
  };

  const showGAOccupationPicker = false;

  const householdTip = `Enter the name of the household member and select the member 
  from the presented list. Multiple household members may be associated to a contact.
  Remove a member by clicking on 'X' next to the member's name. Use the [Update] button to save the changes.`;

  return (
    <div className="ContactForm">
      <SectionSeparator content="Details" alignContent="center" />
      <Stack tokens={stackTokens} horizontalAlignment="center">
        <SourceTypePicker
          label="Contact Type"
          selectedKey={contactState.type}
          onChange={actions.handleChange('type')}
          sourceTypes={contactTypeOptions}
        />
        {(contactState.householdMembers.length || contactState.showHouseholdPicker) &&
          <>
            <Label>Household Members <Tip content={householdTip} /></Label>
            <NormalPeoplePicker
              onResolveSuggestions={onResolveSuggestions}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
              onItemSelected={actions.householdMembers.onHouseholdMemberSelected}
              onRenderItem={onRenderItem}
              selectedItems={contactState.householdMembers}
              placeholder="Enter name of member..."
            />
          </>
        }
        {(contactState._id && !contactState.householdMembers.length && !contactState.showHouseholdPicker) && (
          <ActionButton
            iconProps={{ iconName: 'PlusCircle' }}
            onClick={actions.householdMembers.showHouseholdPicker}
            text="Add Household Members"
          />
        )}
        {!contactState._id && (
          <>
            <label style={{ paddingTop: 8 }}>Household members can be added after the contact is created.</label>
          </>
        )}
        {showGAOccupationPicker &&
        <div>
          <GAOccupationPicker
            onPickerChange={() => {}}
            onOtherChange={() => {}}
            pickerValue="597"
          />
        </div>
        }
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 auto' }}>
            <TextField
              label="Tax ID"
              value={contactState.taxId}
              onChange={actions.handleChange('taxId')}
            />
          </div>
          <div style={{ flex: '1 1 auto', marginTop: '40px', marginLeft: '16px' }}>
            <Checkbox
              label="Track For 1099"
              checked={contactState.trackFor1099}
              onChange={actions.handleChange('trackFor1099')}
            />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {(campaign.officeType === 'State' && campaign.officeState === 'Georgia' && contactState.type === 'Individual') &&
          <div style={{ marginTop: '10px', marginRight: '16px' }}>
            <Checkbox
              label="Is Candidate"
              checked={contactState.isCandidate}
              onChange={actions.handleChange('isCandidate')}
            />
          </div>
          }
          <div style={{ marginTop: '10px' }}>
            <Checkbox
              label="Is Deceased"
              checked={contactState.isDeceased}
              onChange={actions.handleChange('isDeceased')}
            />
          </div>
          <div style={{ marginTop: '10px', marginLeft: '16px' }}>
            <Checkbox
              label="Is Removed"
              checked={contactState.isRemoved}
              onChange={actions.handleChange('isRemoved')}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 auto' }}>
            <TextField
              label="Donor ID"
              value={contactState.externalDonorId}
              onChange={actions.handleChange('externalDonorId')}
            />
          </div>
        </div>
        {['CAN'].includes(contactState.type) && (
          <CandidateForm
            salutation={contactState.salutation}
            firstName={contactState.firstName}
            middleName={contactState.middleName}
            lastName={contactState.lastName}
            suffix={contactState.suffix}
            nickName={contactState.nickName}
            candidateFecId={contactState.candidateFecId}
            employer={contactState.employer}
            occupation={contactState.occupation}
            candidateOffice={contactState.candidateOffice}
            candidateState={contactState.candidateState}
            candidateDistrict={contactState.candidateDistrict}
            handleChange={actions.handleChange}
          />
        )}
        {['TST'].includes(contactState.type) && (
          <TrustForm
            contactName={contactState.contactName}
            businessName={contactState.businessName}
            trustee={contactState.trustee}
            trustor={contactState.trustor}
            isLivingOrRevokableTrust={contactState.isLivingOrRevokableTrust}
            handleChange={actions.handleChange}
          />
        )}
        {['Individual', 'IND'].includes(contactState.type) && (
          <IndividualForm
            handleChange={actions.handleChange}
            salutation={contactState.salutation}
            firstName={contactState.firstName}
            middleName={contactState.middleName}
            lastName={contactState.lastName}
            suffix={contactState.suffix}
            nickName={contactState.nickName}
            occupation={contactState.occupation}
            employer={contactState.employer}
            relationshipToCandidate={contactState.relationshipToCandidate}
          />
        )}
        {['Business', 'ORG'].includes(contactState.type) && (
          <BusinessForm
            type={contactState.type}
            handleChange={actions.handleChange}
            taxEin={contactState.taxEin}
            businessType={contactState.businessType}
            contactName={contactState.contactName}
            businessName={contactState.businessName}
          />
        )}
        {contactState.type === 'Common Source' && (
          <CommonSourceForm
            handleChange={actions.handleChange}
            businessName={contactState.businessName}
            commonSource={contactState.commonSource}
            specific={contactState.specific}
          />
        )}
        {['Committee', 'COM', 'PAC', 'PTY'].includes(contactState.type) && (
          <CommitteeForm
            handleChange={actions.handleChange}
            committeeFecId={contactState.committeeFecId}
            contactType={contactState.type}
            businessName={contactState.businessName}
            contactName={contactState.contactName}
            committeeAffiliation={contactState.committeeAffiliation}
          />
        )}
        {['Candidate Committee'].includes(contactState.type) && (
          <CandidateCommitteeForm
            handleChange={actions.handleChange}
            name={contactState.businessName}
            contactName={contactState.contactName}
          />
        )}
        {['CCM'].includes(contactState.type) && (
          <CandidateCampaignCommitteeForm
            committeeName={contactState.businessName}
            committeeFecId={contactState.committeeFecId}
            candidateName={contactState.candidateName}
            handleChange={actions.handleChange}
            candidateOffice={contactState.candidateOffice}
            candidateState={contactState.candidateState}
            candidateDistrict={contactState.candidateDistrict}
            candidateFecId={contactState.candidateFecId}
            candidateFirstName={contactState.candidateFirstName}
            candidateMiddleName={contactState.candidateMiddleName}
            candidateLastName={contactState.candidateLastName}
          />
        )}
        {contactState.type === 'PAC: multicandidate' && (
          <PacMulticandidateForm
            handleChange={actions.handleChange}
            name={contactState.businessName}
            contactName={contactState.contactName}
          />
        )}
        {contactState.type === 'PAC: non-multicandidate' && (
          <PacNonMulticandidateForm
            handleChange={actions.handleChange}
            name={contactState.businessName}
            contactName={contactState.contactName}
          />
        )}
        {contactState.type === 'Party Committee: state / district / local' && (
          <PartyCommitteeSDLForm
            handleChange={actions.handleChange}
            name={contactState.businessName}
            contactName={contactState.contactName}
          />
        )}
        {contactState.type === 'Party Committee: national' && (
          <PartyCommitteeNationalForm
            handleChange={actions.handleChange}
            name={contactState.businessName}
            contactName={contactState.contactName}
          />
        )}
        <SectionSeparator content="Address" alignContent="center" />
        <Label>Address Search <Tip content="Enter address and select to auto fill" /></Label>
        <AddressBasePicker
          onResolveSuggestions={onResolveAddressSuggestions}
          onRenderSuggestionsItem={onRenderAddressSuggestionsItem}
          onItemSelected={onAddressItemSelect}
          selectedItems={contactState.addressSearchSelection}
          onRenderItem={renderAddressItem}
          placeholder="Enter address..."
        />
        <AddressForm
          handleChange={actions.handleChange}
          handleChangeNewAddress={actions.addresses.handleChangeNewAddress}
          handleChangeAdditionalAddress={
            actions.addresses.handleChangeAdditionalAddress
          }
          handleDeleteAdditionalAddress={
            actions.addresses.handleDeleteAdditionalAddress
          }
          getAddressSearchData={actions.addresses.getAddressSearchData}
          addNewAddressStart={actions.addresses.addNewAddressStart}
          addNewAddressFinish={actions.addresses.addNewAddressFinish}
          cancelAddAddress={actions.addresses.cancelAddAddress}
          addressLine1={contactState.addressLine1}
          addressLine2={contactState.addressLine2}
          city={contactState.city}
          state={contactState.state}
          zipCode={contactState.zipCode}
          county={contactState.county}
          additionalAddresses={contactState.additionalAddresses}
          addAddress={contactState.newAddress.addAddress}
          newAddressLine1={contactState.newAddress.newAddressLine1}
          newAddressLine2={contactState.newAddress.newAddressLine2}
          newCity={contactState.newAddress.newCity}
          newState={contactState.newAddress.newState}
          newZipCode={contactState.newAddress.newZipCode}
          newCounty={contactState.newAddress.newCounty}
          session={session}
        />
        <SectionSeparator content="Emails" />
        <EmailForm
          handleChange={actions.handleChange}
          handleChangeNewEmail={actions.emails.handleChangeNewEmail}
          handleChangeAdditionalEmail={
            actions.emails.handleChangeAdditionalEmail
          }
          handleDeleteAdditionalEmail={
            actions.emails.handleDeleteAdditionalEmail
          }
          addNewEmailStart={actions.emails.addNewEmailStart}
          cancelAddEmail={actions.emails.cancelAddEmail}
          addNewEmailFinish={actions.emails.addNewEmailFinish}
          email={contactState.email}
          additionalEmailAddresses={contactState.additionalEmailAddresses}
          addEmail={contactState.newEmail.addEmail}
          newEmail={contactState.newEmail.newEmail}
        />
        <SectionSeparator content="Phone Numbers" />
        <PhoneForm
          handleChange={actions.handleChange}
          handleChangeNewPhone={actions.phoneNumbers.handleChangeNewPhone}
          handleChangeAdditionalPhone={
            actions.phoneNumbers.handleChangeAdditionalPhone
          }
          handleDeleteAdditionalPhone={
            actions.phoneNumbers.handleDeleteAdditionalPhone
          }
          addNewPhoneStart={actions.phoneNumbers.addNewPhoneStart}
          addNewPhoneFinish={actions.phoneNumbers.addNewPhoneFinish}
          cancelAddPhone={actions.phoneNumbers.cancelAddPhone}
          phone1={contactState.phone1}
          phone2={contactState.phone2}
          additionalPhoneNumbers={contactState.additionalTelephoneNumbers}
          newPhone={contactState.newPhone.newPhone}
          addPhone={contactState.newPhone.addPhone}
        />
        <SectionSeparator content="Notes" />
        {(contactState.notes || []).map(note => (
          <div style={{ display: 'flex' }}>
            <div style={{ width: '95%' }}>
              <TextField
                key={note._id}
                multiline={true}
                value={note.value}
                onChange={actions.notes.handleChangeNote(note._id)}
              />
            </div>
            <Icon
              iconName="times"
              onClick={actions.notes.handleDeleteNote(note._id)}
              styles={{
                root: {
                  marginRight: 6,
                  marginTop: 6,
                  marginLeft: 6,
                  cursor: 'pointer',
                },
              }}
            />
          </div>
        ))}
        {!contactState.newNote.addNote && (
          <ActionButton
            iconProps={{ iconName: 'PlusCircle' }}
            onClick={actions.notes.addNewNoteStart}
            text="Add New Note"
          />
        )}
        {contactState.newNote.addNote && (
          <Fragment>
            <TextField
              label="New Note"
              value={contactState.newNote.newNote}
              onChange={actions.notes.handleChangeNewNote}
            />
            <div>
              <DefaultButton
                text="Cancel"
                onClick={actions.notes.cancelAddNote}
                style={{ marginRight: 16 }}
              />
              <DefaultButton
                text="Save Note"
                onClick={actions.notes.addNewNoteFinish}
              />
            </div>
          </Fragment>
        )}
        {contactState.type === 'Individual' && (
          <Fragment>
            <SectionSeparator content="Family" />
            <FamilyForm
              handleChange={actions.handleChange}
              handleChangeNewChild={actions.children.handleChangeNewChild}
              handleChangeChild={actions.children.handleChangeChild}
              handleDeleteChild={actions.children.handleDeleteChild}
              spouseFirstName={contactState.spouseFirstName}
              spouseMiddleName={contactState.spouseMiddleName}
              spouseLastName={contactState.spouseLastName}
              childrenArray={contactState.children}
              addChild={contactState.newChild.addChild}
              newChild={contactState.newChild.newChild}
              addNewChildStart={actions.children.addNewChildStart}
              addNewChildFinish={actions.children.addNewChildFinish}
              cancelAddChild={actions.children.cancelAddChild}
            />
          </Fragment>
        )}
      </Stack>
    </div>
  );
};

ContactForm.propTypes = {
  contactState: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContactForm;
