import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TooltipHost } from 'office-ui-fabric-react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Label } from 'office-ui-fabric-react/lib/Label';

import {
  Column,
  DataLabel,
  EmailField,
  Grid,
  GridRow,
  HelpIcon,
  PasswordField,
  MoneyField,
} from '../../components/common';
import { CaretDown } from '../../components/icons';
import {
  ElectionCyclePicker,
  FECElectionCyclePicker,
  ReportingFrequencyPicker,
  YearPicker,
  DatePicker,
} from '../../components/Pickers';
import { getLabel } from '../../helpers/labelHelper';
import {
  fecCommitteeTypes,
  fecNonCandidateCommitteeOptions,
  fecPacOrganizationOptions,
  federalPartyAffiliationOptions,
  frCampaignTypes,
  officeTypes,
  timeZones,
} from '../../helpers/constants';
import './EditFilerDetails.css';
import { getLabelFromOptions } from '../../helpers/util';

import { actions as messagingActions } from '../../actions/messagingActions';

const EditFilerDetails = ({
  campaign,
  campaignType,
  incumbentNameError,
  filerEmailError,
  filerDetailsExpanded,
  actions,
  formSection,
  session,
  messagingActions,
}) => {
  const { FederalNonCandidate } = frCampaignTypes;
  const { PacSupportOpposeMoreThanOne, PacSeparateSegregatedFund } = fecCommitteeTypes;
  let federalPacDescription = '';
  let organizationType = '';
  let isLeadership = false;
  let isLobbyist = false;

  if (campaignType === FederalNonCandidate) {
    federalPacDescription = getLabelFromOptions(fecNonCandidateCommitteeOptions, campaign.committee.type);
    if (campaign.committee.type === PacSeparateSegregatedFund) {
      organizationType = getLabelFromOptions(fecPacOrganizationOptions, campaign.committee?.federalPac?.organizationType);
      isLobbyist = campaign.committee?.federalPac?.isLobbyistRegistrantPac;
    }
    if (campaign.committee.type === PacSupportOpposeMoreThanOne) {
      isLobbyist = campaign.committee?.federalPac?.isLobbyistRegistrantPac;
      isLeadership = campaign.committee?.federalPac?.isLeadershipPac;
    }
  }
  const setNextElectionDate = actions.handleDateChange('nextElectionDate');
  const setNextElectionYear = actions.handleTextChange('nextElectionYear');
  const handleNextElectionDateChange = (value) => {
    setNextElectionDate(value);
    setNextElectionYear(null, value.getFullYear().toString());
  };

  const filerLabelText = getLabel('Filer ID', session);
  const filerLabelTip = (
    <TooltipHost
      content="Please contact FrontRunner to update Filer Id"
      closeDelay={750}
    >
      <HelpIcon />
    </TooltipHost>
  );
  const getFilerIdDisabled = () => session.role !== 'SysAdmin' && campaign.officeType === 'State';
  const filerIdLabel = (
    <span>
      {filerLabelText}
      {getFilerIdDisabled() &&
        filerLabelTip
      }
    </span>
  );

  const filerIdChange = (e, typedValue) => {
    const invalidChar = '&';
    const re = new RegExp('[' + invalidChar + ']');
    const hasBadCharacter = re.test(typedValue);
    const isFederal = /federal/.test(campaignType);
    if (hasBadCharacter && isFederal) {
      messagingActions.setErrorToast(invalidChar + ' is not allowed in password');
      return;
    }
    actions.handleTextChange('filerPassword')(e, typedValue);
  };

  const getOfficeType = (campaign) => {
    if (campaign?.candidate?.firstName === 'Brenna' && campaign?.candidate?.lastName === 'Bird') {
      return 'State';
    }
    return campaign.officeType;
  };

  return (
    <div className="EditFilerDetails depth-1" name="filer-details">
      <h3>
        Filer Information{' '}
        <small>
          {' '}
          (
          <Link onClick={actions.toggleExpandFilerDetails}>
            {filerDetailsExpanded ? 'Collapse' : 'Expand'}
          </Link>
          )
        </small>
      </h3>
      <div
        className={`edit-filer-details-content${
          filerDetailsExpanded ? ' expanded' : ''
        }`}
      >
        {!campaign.isNonCandidateCommittee && (
          <Grid className="office-info">
            <GridRow>
              <Column>
                <DataLabel
                  size="large"
                  style={{ borderBottom: '1px solid #999', paddingBottom: 8 }}
                  label="Office Type"
                  value={getOfficeType(campaign)}
                />
              </Column>
            </GridRow>
            <GridRow>
              <Column>
                <DataLabel
                  size="large"
                  style={{ borderBottom: '1px solid #999', paddingBottom: 8 }}
                  label="Office State"
                  value={campaign.officeState}
                />
              </Column>
            </GridRow>
            <GridRow>
              <Column>
                <DataLabel
                  size="large"
                  style={{ borderBottom: '1px solid #999', paddingBottom: 8 }}
                  label="Office"
                  value={getLabel(campaign.office, session)}
                />
              </Column>
            </GridRow>
            {campaign.subOffice && (
              <GridRow>
                <Column>
                  <DataLabel
                    size="large"
                    style={{
                      borderBottom: '1px solid #999',
                      paddingBottom: 8,
                    }}
                    label="Sub Office"
                    value={
                      campaign.officeType === officeTypes.FEDERAL &&
                      campaign.office === 'H'
                        ? `District ${campaign.subOffice}`
                        : campaign.subOffice
                    }
                  />
                </Column>
              </GridRow>
            )}
            <GridRow>
              <Column>
                <DatePicker
                  label="Next Election Date"
                  value={campaign.nextElectionDate}
                  onChange={handleNextElectionDateChange}
                />
              </Column>
            </GridRow>
            <GridRow>
              <Column>
                <Label>
                  Next Election Year: {campaign.nextElectionYear}
                </Label>
              </Column>
            </GridRow>
            {session.isFederal() && (
              <GridRow>
                <Column>
                  <MoneyField
                    label="Carry Forward from Previous Election Cycle (General Election)"
                    value={campaign.previousElectionCycleCarryForwardAmount}
                    onChange={actions.handleTextChange('previousElectionCycleCarryForwardAmount')}
                  />
                </Column>
              </GridRow>
            )}
            {session.isFederal() && (
              <GridRow>
                <Column>
                  <ReportingFrequencyPicker
                    value={campaign.reportingFrequency}
                    handleChange={actions.handleTextChange('reportingFrequency')}
                  />
                </Column>
              </GridRow>
            )}
          </Grid>
        )}

        {campaignType === FederalNonCandidate && (
          <Grid className="office-info">
            <GridRow>
              <GridRow>
                <Column>
                  <DataLabel
                    size="large"
                    style={{ borderBottom: '1px solid #999', paddingBottom: 8 }}
                    label="Committee Type"
                    value={federalPacDescription}
                  />
                </Column>
              </GridRow>
              {campaign.committee.type === PacSeparateSegregatedFund &&
              <Column>
                <DataLabel
                  size="large"
                  style={{
                    borderBottom: '1px solid #999',
                    paddingBottom: 8,
                  }}
                  label="Organization Type"
                  value={organizationType}
                />
              </Column>
              }
            </GridRow>
            {isLobbyist &&
            <GridRow>
              <Column>
                <DataLabel
                  size="large"
                  style={{ borderBottom: '1px solid #999', paddingBottom: 8 }}
                  label="Lobbyist/Registrant PAC"
                  value="Yes"
                />
              </Column>
            </GridRow>
            }
            {isLeadership &&
            <GridRow>
              <Column>
                <DataLabel
                  size="large"
                  style={{ borderBottom: '1px solid #999', paddingBottom: 8 }}
                  label="Leadership PAC"
                  value="Yes"
                />
              </Column>
            </GridRow>
            }
          </Grid>
        )}
        <Grid className="filer-info">
          <GridRow>
            <Column lg={6}>
              <TextField
                label={filerIdLabel}
                value={campaign.filerId}
                onChange={actions.handleTextChange('filerId')}
                disabled={getFilerIdDisabled()}
                required
              />
            </Column>
            <Column lg={6}>
              <PasswordField
                label={getLabel('Filer PIN', session)}
                value={campaign.filerPassword}
                onChange={filerIdChange}
                required
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column lg={session.isFederal() ? 6 : 12}>
              <EmailField
                label="Filer Email Address"
                value={campaign.filerEmail}
                onChange={actions.handleTextChange('filerEmail')}
                required
                errorMessage={filerEmailError}
                setFormInvalid={actions.setFormInvalid}
                formSection={formSection}
              />
            </Column>
            {session.isFederal() && (
              <Column lg={6}>
                <TextField
                  prefix="http(s)://"
                  label="Committee's Web Page Address"
                  value={campaign.committee.websiteUrl}
                  onChange={actions.handleCommitteeTextChange('websiteUrl')}
                />
              </Column>
            )}
          </GridRow>
          {campaignType === frCampaignTypes.FederalCandidate && (
            <GridRow>
              <Column lg={6}>
                <Dropdown
                  onRenderCaretDown={() => <CaretDown />}
                  label="Candidate Party Affiliation"
                  options={[
                    { key: 0, text: 'Not Selected' },
                    ...federalPartyAffiliationOptions,
                  ]}
                  placeholder="Select"
                  selectedKey={campaign.partyAffiliation}
                  onChange={actions.handlePartyAffiliationChange}
                  required
                />
              </Column>
              <Column lg={6} style={{ paddingTop: 34 }}>
                <Checkbox
                  label="This committee is a principle campaign committee"
                  required
                  checked={Boolean(campaign.committee.type)}
                  onChange={actions.handleFecCommitteeTypeChange}
                />
              </Column>
            </GridRow>
          )}
          {!campaign.isNonCandidateCommittee && (
            <GridRow>
              <Column sm={6} classNames="incumbent-checkbox">
                <Toggle
                  onText="Yes"
                  offText="No"
                  checked={campaign.isIncumbent}
                  label="Are you the incumbent?"
                  onChange={actions.toggleIncumbent}
                />
              </Column>
              {!campaign.isIncumbent && (
                <Column sm={6}>
                  <TextField
                    label="Incumbent Name"
                    value={campaign.incumbentName}
                    onChange={actions.handleTextChange('incumbentName')}
                    errorMessage={incumbentNameError}
                    formSection={formSection}
                  />
                </Column>
              )}
            </GridRow>
          )}
          <GridRow>
            <Column sm={3}>
              <TooltipHost
                content={`Import fields are used with automated imports of ${getLabel('contributions', session)}.`}
                closeDelay={750}
              >
                <HelpIcon />
              </TooltipHost>
              <YearPicker
                label="Import Election Year"
                value={parseInt(campaign?.settings?.importDefaults?.electionYear, 10)}
                onChange={actions.handleImportDefaultsChange('electionYear')}
              />
            </Column>
            <Column sm={3}>
              <div style={{ height: '24px' }}>&nbsp;</div>
              {session.isFederal() ? (
                <FECElectionCyclePicker
                  label="Import Election"
                  value={campaign?.settings?.importDefaults?.electionCycle}
                  onChange={actions.handleImportDefaultsChange('electionCycle')}
                />
              ) : (
                <ElectionCyclePicker
                  value={campaign?.settings?.importDefaults?.electionCycle}
                  label="Import Election"
                  onChange={actions.handleImportDefaultsChange('electionCycle')}
                />
              )}
            </Column>
            <Column sm={6}>
              <Dropdown
                onRenderCaretDown={() => <CaretDown />}
                label="Time Zone"
                options={[
                  { key: '', text: 'Not Selected' },
                  ...timeZones,
                ]}
                placeholder="Select"
                selectedKey={campaign.timeZoneName}
                onChange={actions.handleTextChange('timeZoneName')}
              />
            </Column>
          </GridRow>
          {(campaign.isNonCandidateCommittee && campaignType !== FederalNonCandidate) && (
            <>
              <GridRow>
                <Column lg={3}>
                  <DatePicker
                    label="Next Election Date"
                    value={campaign.nextElectionDate}
                    onChange={handleNextElectionDateChange}
                  />
                </Column>
                <Column lg={3}>
                  <Label>
                    Next Election Year: {campaign.nextElectionYear}
                  </Label>
                </Column>
              </GridRow>
            </>
          )}
          <GridRow>
            <Column sm={12}>
              <TextField
                label="Managed By"
                value={campaign?.managedBy}
                onChange={actions.handleTextChange('managedBy')}
                formSection={formSection}
                maxLength={100}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column sm={12}>
              <TextField
                label="Description"
                value={campaign?.description}
                onChange={actions.handleTextChange('description')}
                formSection={formSection}
                maxLength={100}
              />
            </Column>
          </GridRow>
        </Grid>
      </div>
      {filerDetailsExpanded && (
        <div className="ms-textAlignRight">
          <DefaultButton
            text="Continue"
            onClick={() =>
              actions.nextStep(campaign.isNonCandidateCommittee ? 2 : 1)
            }
          />
        </div>
      )}
    </div>
  );
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    messagingActions: bindActionCreators(messagingActions, dispatch),
  };
}

EditFilerDetails.propTypes = {
  campaign: PropTypes.object,
  campaignType: PropTypes.string,
  incumbentNameError: PropTypes.string,
  filerEmailError: PropTypes.string,
  filerDetailsExpanded: PropTypes.bool,
  formSection: PropTypes.number,
  actions: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  messagingActions: PropTypes.object.isRequired,
};

EditFilerDetails.defaultProps = {
  campaign: {},
  incumbentNameError: '',
  filerEmailError: '',
  filerDetailsExpanded: true,
  formSection: null,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditFilerDetails),
);
